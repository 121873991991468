import { library } from "@fortawesome/fontawesome-svg-core";
import hold from "./pzse/hold";
import warning from "./pzse/warning";
import notification from "./pzse/notification";
import claimed from "./pzse/claimed";
import upgrade from "./pzse/upgrade";
import redline from "./pzse/redline";
import engineering from "./pzse/engineering";
import comments from "./pzse/comments";
import next from "./pzse/next";
import wetstamp from "./pzse/wet-stamp";
import document from "./pzse/document";
import search from "./pzse/search";
import bell from "./pzse/bell";
import fullStructural from "./pzse/full-structural";
import checkCircle from "./pzse/check-circle";
import warningInverse from "./pzse/warning-circle";
import management from "./pzse/management";
import image from "./pzse/image";
import paperclip from "./pzse/paperclip";
import truck from "./pzse/truck";
import inProgress from "./pzse/in-progress";
import email from "./pzse/email";
import statusContinue from "./pzse/status-continue";
import holdCircle from "./pzse/hold-circle";
import filterDelete from "./pzse/filter-delete";
import clientRefresh from "./pzse/client-refresh";
import commentIcon from "./pzse/comment-icon";
import filterPrint from "./pzse/filter-print";
import filterSearch from "./pzse/filter-search";
import filterSearch2 from "./pzse/filter-search-2";
import filterExport from "./pzse/filter-export";
import filterArchived from "./pzse/filter-archived";
import refresh from "./pzse/refresh";
import editButton from "./pzse/edit-button";
import deleteButton from "./pzse/delete-button";
import customPrice from "./pzse/custom-price";
import userComment from "./pzse/user-comment";
import standardComment from "./pzse/standard-comment";
import commentProgress from "./pzse/comment-progress";
import resolved from "./pzse/resolved";
import send from "./pzse/send";
import image2 from "./pzse/image-2";
import fullStructuralType from "./pzse/full-structural-type";
import electricalStampType from "./pzse/electrical-stamp-type";
import iebcLetterType from "./pzse/iebc-letter-type";
import electricalFaultStudyType from "./pzse/electical-fault-study-type";
import engineer from "./pzse/engineer";
import commentIcon2 from "./pzse/comment-icon-2";
import ahjCard from "./pzse/ahj-card";
import recycleBin from "./pzse/recycle-bin";
import importFile from "./pzse/import-file";
import ahjExchange from "./pzse/ahj-exchange";
import ahjArchived from "./pzse/ahj-archived";
import ahjImage from "./pzse/ahj-image";
import ahjTable from "./pzse/ahj-table";
import eye from "./pzse/eye";
import filterFaq from "./pzse/filter-faq";
import threeDots from "./pzse/three-dots";
import arrowRightCircle from "./pzse/arrow-right-circle";
import filter from "./pzse/filter";
import archive from "./pzse/archive";
import download from "./pzse/download";
import legacy from "./pzse/legacy";
import patchNotes from "./pzse/patch-notes";
import projects from "./pzse/projects";
import transactions from "./pzse/transactions";
import suspend from "./pzse/suspend";
import unsuspend from "./pzse/unsuspend";

/*--------------------------------------------------------------
 | PZSE Custom Icons
 |--------------------------------------------------------------
 | To use custom icon example `<i class="fapzse fa-hold"></i>`
 |
 */
library.add(hold);
library.add(warning);
library.add(notification);
library.add(claimed);
library.add(upgrade);
library.add(redline);
library.add(engineering);
library.add(comments);
library.add(next);
library.add(wetstamp);
library.add(document);
library.add(search);
library.add(bell);
library.add(fullStructural);
library.add(checkCircle);
library.add(warningInverse);
library.add(management);
library.add(image);
library.add(paperclip);
library.add(truck);
library.add(inProgress);
library.add(email);

library.add(statusContinue);
library.add(holdCircle);
library.add(filterDelete);
library.add(clientRefresh);
library.add(commentIcon);
library.add(filterPrint);
library.add(filterSearch);
library.add(filterExport);
library.add(filterArchived);
library.add(refresh);
library.add(editButton);
library.add(deleteButton);
library.add(customPrice);
library.add(userComment);
library.add(standardComment);
library.add(resolved);
library.add(commentProgress);
library.add(send);
library.add(image2);
library.add(commentIcon2);
library.add(fullStructuralType);
library.add(electricalStampType);
library.add(iebcLetterType);
library.add(electricalFaultStudyType);
library.add(engineer);
library.add(ahjCard);
library.add(recycleBin);
library.add(importFile);
library.add(ahjExchange);
library.add(ahjArchived);
library.add(ahjImage);
library.add(ahjTable);
library.add(eye);
library.add(filterFaq);
library.add(threeDots);
library.add(arrowRightCircle);
library.add(filter);
library.add(archive);
library.add(download);
library.add(legacy);
library.add(patchNotes);
library.add(projects);
library.add(transactions);
library.add(suspend);
library.add(unsuspend);
library.add(filterSearch2);

/* Project Progress Icons */
import progressEngineering from "./pzse/progress-engineering-circle";
import progressFour from "./pzse/progress-four-circle";
import progressThree from "./pzse/progress-three-circle";

library.add(progressEngineering);
library.add(progressFour);
library.add(progressThree);
/* Project Progress Icons */

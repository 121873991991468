export default {
  prefix: "fapzse",
  iconName: "filter-search-2",
  icon: [
    11,
    12,
    [],
    "asfcb113",
    "M6.5 12L3.5 9.75V7.19L-0.5 2.69V1.5C-0.5 1.10218 -0.341965 0.720644 -0.0606601 0.43934C0.220644 0.158035 0.602175 0 1 0L9 0C9.39783 0 9.77936 0.158035 10.0607 0.43934C10.342 0.720644 10.5 1.10218 10.5 1.5V2.69L6.5 7.19V12Z"
  ]
};
